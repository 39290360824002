






























































import { Component, Vue } from 'vue-property-decorator'
import BackDashboardButton from '@/components/BackDashboardButton.vue'
import EmailInputRenderer from '@/components/facts/renderers/EmailInputRenderer.vue'
import ListRenderer from '@/components/facts/renderers/ListRenderer.vue'
import MultilineRenderer from '@/components/facts/renderers/MultilineRenderer.vue'
import constants from '@/constants'
import { FactValidationEvent } from '@/interfaces/FactValidationEvent'
import { FactChangeEvent } from '@/interfaces/FactChangeEvent'
import { Shift } from '@/services/Shift'
import { Config } from '@/config'
import { Getter } from 'vuex-class'
import axios from 'axios'
import jwtDecode from 'jwt-decode'

@Component({
  components: { EmailInputRenderer, MultilineRenderer, ListRenderer, BackDashboardButton }
})
export default class ContactUsEmail extends Vue {
  private ROUTE_NAME = constants.routeNames

  @Getter('user/email')
  private email!: string

  @Getter('user/givenName')
  private givenName!: string

  @Getter('user/familyName')
  private familyName!: string

  private invalidFacts: string[] = []

  private additionalDetailsRegex = /^(.|\r|\n){1,1000}$/

  private emailAddressFact = {
    id: 'emailAddress',
    mandatory: true,
    name: this.$t('contactUsEmail.field.email'),
    currentValue: ''
  }

  private supportCategoryFact = {
    id: 'supportCategory',
    name: this.$t('contactUsEmail.field.category'),

    options: [
      { value: 'ABOUT_SERVICE', name: this.$t('contactUsEmail.field.options.aboutService') },
      { value: 'ABOUT_REPAIRS', name: this.$t('contactUsEmail.field.options.aboutRepairs') },
      { value: 'ABOUT_CONTRACT', name: this.$t('contactUsEmail.field.options.aboutContract') },
      { value: 'CHANGE_CUSTOMER_INFORMATION', name: this.$t('contactUsEmail.field.options.changeCustomerInfo') },
      { value: 'CANCEL_SERVICE', name: this.$t('contactUsEmail.field.options.cancelService') },
      { value: 'SERVICE_WEBSITE_FAILURE', name: this.$t('contactUsEmail.field.options.serviceWebsiteFailure') },
      { value: 'OTHER', name: this.$t('contactUsEmail.field.options.other') }
    ],
    currentValue: ''
  }

  private additionalDetailsFact = {
    id: 'additionalDetails',
    mandatory: true,
    name: this.$t('contactUsEmail.field.additionalDetails'),
    currentValue: ''
  }

  mounted() {
    if (this.email) {
      this.emailAddressFact.currentValue = this.email
    }
  }

  emailAddressSet(evt: FactChangeEvent) {
    if (!this.fieldInError(evt.id)) {
      this.emailAddressFact.currentValue = evt.value
    }
  }

  categorySet(evt: FactChangeEvent) {
    if (!this.fieldInError(evt.id)) {
      this.supportCategoryFact.currentValue = evt.value
    }
  }

  additionalDetailsSet(evt: FactChangeEvent) {
    if (!this.fieldInError(evt.id)) {
      this.additionalDetailsFact.currentValue = evt.value
    }
  }

  onFactValidation(evt: FactValidationEvent) {
    if (evt.valid) {
      const ix = this.invalidFacts.indexOf(evt.id)
      if (ix > -1) {
        this.invalidFacts.splice(ix, 1)
      }
    } else if (!this.invalidFacts.includes(evt.id)) {
      this.invalidFacts.push(evt.id)
    }
  }

  fieldInError(id: string) {
    return this.invalidFacts.find((invalidFieldId) => { return invalidFieldId === id })
  }

  isViewValid() {
    return this.invalidFacts.length === 0 &&
      this.emailAddressFact.currentValue !== '' &&
      this.supportCategoryFact.currentValue !== '' &&
      this.additionalDetailsFact.currentValue !== ''
  }

  async submitEnquiry() {
    const selectedOption = this.supportCategoryFact.options.find(option => {
      return option.value === this.supportCategoryFact.currentValue
    })
    const ip = await axios.get('https://api.ipify.org?format=json')
      .then((response: any) => response?.data?.ip || '0.0.0.0')
      .catch(() => '0.0.0.0')

    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    const enquiryDetails = {
      firstName: this.givenName,
      lastName: this.familyName,
      customerEmailAddress: this.emailAddressFact.currentValue,
      category: selectedOption!!.name,
      additionalDetails: this.additionalDetailsFact.currentValue,
      supportPhoneNumber: Config.supportContact.phoneNumber,
      ip,
      device: navigator.userAgent,
      parsedToken: {}
    }

    const token = sessionStorage.getItem('otp-token') || sessionStorage.getItem('guest-token')
    if (token) {
      const parsedToken: any = jwtDecode(token)
      enquiryDetails.parsedToken = {
        client: parsedToken?.cid || '',
        tokenId: parsedToken?.jti || '',
        userId: parsedToken?.uid || ''
      }
    }
    await Shift.submitEmailContactEnquiry(enquiryDetails)

    await this.$router.push({ name: constants.routeNames.CONTACT_US_EMAIL_SUCCESS })
  }
}

