










































import { Component, Vue } from 'vue-property-decorator'
import BackDashboardButton from '@/components/BackDashboardButton.vue'
import constants from '@/constants'
import { Config } from '@/config'

@Component({
  components: { BackDashboardButton }
})
export default class ContactUs extends Vue {
  private contactTelephoneNumber = Config.supportContact.phoneNumber
  private contactTelephoneNumberHref = `tel:${Config.supportContact.phoneNumber}`

  openContactUsForm() {
    this.$router.push({ name: constants.routeNames.CONTACT_US_EMAIL })
  }

  goToServiceIntroduction() {
    this.$modal.show(constants.modalNames.SERVICE_INTRODUCTION_MODAL)
  }
}

