





















import { Component, Vue } from 'vue-property-decorator'
import BackDashboardButton from '@/components/BackDashboardButton.vue'
import ImportantNote from '@/components/ImportantNote.vue'
import Success from '@/components/Success.vue'

@Component({
  components: {
    BackDashboardButton,
    ImportantNote,
    Success
  }
})
export default class ContactUsEmailSuccess extends Vue {}
